import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';

import './Contact.css';

const Contact = ({ darkMode }) => {

    const [formData, setFormData] = useState({
        user_name: '',
        user_email: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const [statusMessage, setStatusMessage] = useState('');
    const [messageStatus, setMessageStatus] = useState({ type: '', text: '' }); // Add a new state for message type

    const form = useRef();
    const recaptchaRef = useRef();  // Adjust reference to `recaptchaRef`

    const sendEmail = (e) => {
        e.preventDefault();

        // Get the token from reCAPTCHA
        const captchaValue = recaptchaRef.current.getValue();

        if (!captchaValue) {
            setStatusMessage('Please complete the reCAPTCHA.');
            return;
        }

        emailjs
            .sendForm('service_5xgbqdq', 'template_pi5j1fa', form.current, {
                publicKey: 'ltunE43OHXON2dhV2',
            })
            .then(
                () => {
                    setMessageStatus({
                        type: 'success',
                        text: 'Thank you for your message! I will get back to you as soon as possible.'
                    });
                    setFormData({
                        user_name: '',
                        user_email: '',
                        message: ''
                    });
                    recaptchaRef.current.reset();

                    // Clear success message after 5 seconds
                    setTimeout(() => {
                        setMessageStatus({ type: '', text: '' });
                    }, 5000);
                },
                (error) => {
                    console.log('FAILED...', error.text);
                    setMessageStatus({
                        type: 'error',
                        text: 'Something went wrong. Please try again later or contact me directly via email.'
                    });
                },
            );
    };

    return (
        <section className={`contact ${darkMode ? 'dark' : ''}`} id="contact">
            <h2>Contact Me</h2>
            <form ref={form} onSubmit={sendEmail} className="contact-form">
                <div className="form-group">
                    <label htmlFor="name">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="user_name"
                        value={formData.user_name}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="email">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="user_email"
                        value={formData.user_email}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className="form-group">
                    <label htmlFor="message">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                    ></textarea>
                </div>
                <ReCAPTCHA
                    ref={recaptchaRef}  // Assign ref to the ReCAPTCHA component
                    sitekey="6Ldb_SMqAAAAABWGiYIWrbAPfxf0jODnUpp1ypqU"
                />
                <button type="submit" className="submit-btn">Send Message</button>
                {statusMessage && <p>{statusMessage}</p>}
                {messageStatus.text && (
                    <div className={`status-message ${messageStatus.type}`}>
                        {messageStatus.type === 'success' && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                            </svg>
                        )}
                        {messageStatus.type === 'error' && (
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                        )}
                        {messageStatus.text}
                    </div>
                )}
            </form>
        </section>
    );
}

export default Contact;
