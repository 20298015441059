import React from 'react';
import './Hero.css';
import videoSrc from '../assets/grafos.mp4'; // path for the video
import profilePic from '../assets/FOTO_miller.png'; // Update the path to your image


const Hero = () => {
    return (
        <div className="hero" id="home">
            <video src={videoSrc} autoPlay muted loop></video>
            <div className="hero-content">
                <h1>Hi, I'm Miller</h1>
                <div class="profile-pic-wrapper">
                    <img src={profilePic} alt="Profile" className="profile-pic" />
                </div>
                <p>I'm a Computer Science professional focused on building a career in Cybersecurity</p>
                <a href="#projects" className="hero-btn">View My Work</a>
            </div>
        </div>
    );
}

export default Hero;
