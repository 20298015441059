import React from 'react';
import './Projects.css';
// Add these icons if you want to use Font Awesome
import { FaGithub, FaExternalLinkAlt } from 'react-icons/fa';

const projectsData = [
    {
        title: 'Keylogger',
        description: 'Captures keystrokes and logs them, handling basic text input and Caps Lock.',
        techStack: ['Python', 'Windows API'],
        link: 'https://github.com/Miller-RS/Keylogger',
        github: 'https://github.com/Miller-RS/Keylogger',
        image: '/images/keylogger_preview.jpg', // Add project preview image
        features: [
            'Keystroke capturing and logging',
            'Caps Lock handling',
            'Text input monitoring'
        ]
    },
    {
        title: 'reCAPTCHA bruteforce',
        description: 'reCAPTCHA bypass for login automation using Python & Node.js.',
        techStack: ['Python', 'Node.js', 'Network'],
        link: 'https://github.com/Miller-RS/recaptcha-v3-login-bruteforce?tab=readme-ov-file',
        github: 'https://github.com/Miller-RS/recaptcha-v3-login-bruteforce?tab=readme-ov-file',
        image: '/images/recaptcha.jpg', // Add project preview image
        features: [
            'Automated reCAPTCHA v3 token generation',
            'CSRF token extraction using Selenium',
            'Programmatic login attempts',
            'Password list iteration',
            'Session cookie management'
        ]
    },
    // {
    //     title: 'Project Three',
    //     description: 'A brief description of the project and what it does.',
    //     techStack: ['JavaScript', 'HTML', 'CSS'],
    //     link: '#'
    // },
];

const Projects = ({ darkMode }) => {
    return (
        <section className={`projects ${darkMode ? 'dark' : ''}`} id="projects">
            <h2>Projects</h2>
            <div className="projects-grid">
                {projectsData.map((project, index) => (
                    <div key={index} className="project-card">
                        {project.image && (
                            <div className="project-image">
                                <img src={project.image} alt={project.title} />
                            </div>
                        )}
                        <div className="project-content">
                            <h3>{project.title}</h3>
                            <p className="project-description">{project.description}</p>

                            <div className="tech-stack">
                                {project.techStack.map((tech, i) => (
                                    <span key={i} className="tech-tag">{tech}</span>
                                ))}
                            </div>

                            {project.features && (
                                <ul className="project-features">
                                    {project.features.map((feature, i) => (
                                        <li key={i}>{feature}</li>
                                    ))}
                                </ul>
                            )}

                            <div className="project-links">
                                {project.github && (
                                    <a href={project.github}
                                        className="github-link"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FaGithub /> View Code
                                    </a>
                                )}
                                {project.link && project.link !== project.github && (
                                    <a href={project.link}
                                        className="demo-link"
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <FaExternalLinkAlt /> Live Demo
                                    </a>
                                )}
                            </div>
                        </div>
                    </div>
                ))}
            </div>
        </section>
    );
}

export default Projects;
