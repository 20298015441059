import React from 'react';
import './SocialLinks.css';

const SocialLinks = ({ darkMode }) => {
    const socialLinks = [
        {
            name: 'LinkedIn',
            url: 'https://www.linkedin.com/in/millerrojas/',
            icon: '/images/linkedin-icon.png'
        },
        {
            name: 'GitHub',
            url: 'https://github.com/Miller-RS',
            icon: '/images/github-icon.png'
        }
    ];

    return (
        <div className={`social-links ${darkMode ? 'dark' : ''}`}>
            {socialLinks.map((social, index) => (
                <a
                    key={index}
                    href={social.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="social-link"
                    aria-label={social.name}
                >
                    <img
                        src={social.icon}
                        alt={social.name}
                        className="social-icon"
                    />
                </a>
            ))}
        </div>
    );
};

export default SocialLinks;