import React from 'react';
import './Tools.css';

const toolsData = [
    {
        icon: '/images/excel-icon.png',
        name: 'Excel',
        description: 'Advanced Excel skills'
    },
    {
        icon: '/images/powerbi-icon.png',
        name: 'Power BI',
        description: 'Data visualization and analytics'
    },
    {
        icon: '/images/notion-icon.png',
        darkIcon: '/images/notiondark-icon.png',
        name: 'Notion',
        description: 'Documentation and knowledge base'
    },
    {
        icon: '/images/trello-icon.png',
        name: 'Trello',
        description: 'Project management'
    },
    // Add more tools as needed
    {
        icon: '/images/miro-icon.png',
        name: 'Miro',
        description: 'Project management'
    },

];

// Duplicate for infinite scroll
const extendedToolsData = [...toolsData, ...toolsData];

const Tools = ({ darkMode }) => {
    return (
        <section className={`tools ${darkMode ? 'dark' : ''}`} id="tools">
            <h2>Tools & Technologies</h2>
            <div className="tools-container">
                <div className="tools-track">
                    {extendedToolsData.map((tool, index) => (
                        <div
                            key={index}
                            className="tool-item"
                            data-tooltip={tool.description}
                        >
                            <div className="tool-icon">
                                <img
                                    src={darkMode && tool.darkIcon ? tool.darkIcon : tool.icon}
                                    alt={tool.name}
                                    width="48"
                                    height="48"
                                />
                            </div>
                            <span className="tool-name">{tool.name}</span>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};
export default Tools;