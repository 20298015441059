// export default App;
import { useState } from 'react';


import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Tools from './components/Tools';
import SocialLinks from './components/SocialLinks';


function App() {

  const [darkMode, setDarkMode] = useState(false);

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.body.classList.toggle('dark-mode', !darkMode);
  };

  return (
    <div className="App">
      <Header darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <Hero />
      <About darkMode={darkMode} />
      <Tools darkMode={darkMode} />
      <Projects darkMode={darkMode} toggleDarkMode={toggleDarkMode} />
      <Contact darkMode={darkMode} />
      <SocialLinks darkMode={darkMode} />
      {/* Other components like Hero, About, etc. will go here */}
    </div>
  );
}

export default App;

